<template>
    <a-modal
      centered
      @cancel="close"
      v-model="show"
      destroyOnClose
      :footer='null'
      :width="400"
      :closable="false"
    >
      <div class="modalIcon" @click="close"><a-icon type="close" /></div>
      <div class="addAccountInput" style="height:60px;margin-top:10px">
        <span>头像</span>
        <div>
            <a-upload
                name="avatar"
                listType="picture-card"
                class="avatar-uploader"
                :showUploadList="false"
                :beforeUpload="beforeUpload"
                @change="handleChange"
                :customRequest="customRequest"
            >
                <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                <div v-else class="imgBg">
                </div>
            </a-upload>
        </div>
      </div>
      <div class="addAccountInput">
        <span>名称</span>
        <div>
            <a-input placeholder="名称" v-model="data.nickname"/>
        </div>
      </div>
       <div class="addAccountInput">
        <span>工号</span>
        <div>
            <a-input placeholder="工号" v-model="data.no"/>
        </div>
      </div>
       <div class="addAccountInput">
        <span>账号</span>
        <div>
            <a-input placeholder="账号" v-model="data.username"/>
        </div>
      </div>
       <div class="addAccountInput">
        <span>密码</span>
        <div>
            <a-input placeholder="密码" type="password" v-model="data.password"/>
        </div>
      </div>
    <button class="btn" @click="submit">确定</button>
    </a-modal>
</template>
<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
import { addUser, uploadImg, editUser } from '@/libs/api'
import { getToken } from '@/libs/auth'
export default {
    name:'addAccount',
    props: {
      visible: Boolean,
      editData:{
        type:Object,
        default:()=>({})
      }
    },
    mounted(){
      if(JSON.stringify(this.editData) !== '{}'){
        let { headImg, nickname, username, no } = this.editData
        this.imageUrl = headImg
        this.data.username = username
        this.data.nickname = nickname 
        this.data.no = no
      }
      // if(JSON.stringify(this.editData) == '{}') {

      // }else{

      // }
    },
    data(){
        return{
          data:{
            password:'',
            username:'',
            nickname:'',
            no:'',
            group_id:['2']
          },
          show:this.visible,
          name:'',
          loading: false,
          imageUrl: '',
        }
    },
    methods:{
      getToken,
      close(){
          this.$emit('cancel',false)
      },
      handleChange(info) {
        if (info.file.status === 'uploading') {
          this.loading = true;
          return;
        }
        if (info.file.status === 'done') {
          getBase64(info.file.originFileObj, imageUrl => {
            this.imageUrl = imageUrl;
            this.loading = false;
          });
        }
      },
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        if (!isJPG) {
          this.$message.error('只能上传jpg图片!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('图片不能超过2MB!');
        }
        return isJPG && isLt2M;
      },
      customRequest(data){
        let formData = new FormData()
        formData.append('file',data.file)
        uploadImg(formData).then(res=>{
          this.imageUrl=res.fileUrl
        },error=>{
          console.log(error)
        })
      },
      submit(){
        if(!this.data.nickname){
          this.$message.error('昵称不能为空')
          return false
        }
        if(!this.data.no){
          this.$message.error('工号不能为空')
          return false
        }
        if(!this.data.username){
          this.$message.error('账号不能为空')
          return false
        }
        if(JSON.stringify(this.editData) == '{}' && !this.data.password){
          this.$message.error('密码不能为空')
          return false
        }
        if(JSON.stringify(this.editData) !== '{}'){
          editUser({...this.data,headImg:this.imageUrl, id:this.editData.id}).then(res=>{
            if(res.length==0){
              this.$emit('cancel',false)
              this.$emit('submit')
            }
          },error=>{
            console.log(error)
          })
        }else{
          addUser({...this.data,headImg:this.imageUrl}).then(res=>{
              this.$emit('cancel',false)
              this.$emit('submit')
          },error=>{
            console.log(error)
          })
        }
      }
    }
}
</script>
<style scoped lang="less">
.addAccountInput{
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    height: 50px;
    align-items: center;
    /deep/ .ant-upload.ant-upload-select-picture-card{
        width: 55px;
        height: 55px;
        border: none;
         background: #5180e0;
         border-radius: 100%;
         overflow: hidden;
    }
    /deep/  .ant-upload img{
       width: 100%;
       height: 100%;
    }
    /deep/ .ant-upload.ant-upload-select-picture-card > .ant-upload{
            display: block;
        padding: 0;
         overflow: hidden;
         width: 55px;
         height: 55px;
    }
    span{
        width: 80px;
        font-size: 16px;
        color: #333333
    }
    >div{
        flex: 1;
        text-align: right;
        .avatar-uploader{
            display: inline-block
        }
    }
    .ant-input,.ant-input:focus{
        border: none;
        box-shadow: none;
        font-size: 16px;
        text-align: right
    }
}
.btn{
    margin: 30px auto 0;
    width: 180px;
    height: 40px;
    border-radius: 40px;
    display: block;
    cursor: pointer;
}
</style>