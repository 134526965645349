<template>
  <div class="task">
    <input type="text" v-model="copyData" ref="copyInput" v-show="false"/>
    <Loading v-if="loading"></Loading>
    <AddAccount :visible="addShow" @cancel="(value)=>{addShow=value}" v-if="addShow"></AddAccount>
    <div class="clearfix">
            <span class="title fL">
              <a-button class="btn" style="margin-right:20px" :class="{active:index == detail_type}" v-for="(item,index) in classB" :key="index" @click="changeClass(item)">{{item.name}}</a-button>
            </span>
      <a-button class="btn fR"  @click="$router.push('/home/addTask')"><a-icon type="plus"/>添加试卷</a-button>
    </div>
    <div v-if="!list.length && !loading" style="line-height:200px;font-size:20px;text-align:center">暂无数据</div>
    <div style="padding-top:40px;min-height:600px" v-if="list.length && !loading">
      <div class="examList" v-for="(item, index) in list" :key="index">
        <div>
          <span class="num">{{index | num}}</span>
          <span class="name">{{item.title}}<span class="count">[{{ item.total_count }}]</span></span>
        </div>
        <div class="iconGroup">
          <div>
            <a-icon type="tool" @click="routeTo('addTask', item.id)" title="修改"/>
          </div>
          <div>
            <icon-font type="icon-bianji" title="编辑试卷" @click="routeTo('EditTask', item.id)"/>
          </div>
          <div>
            <div class="headImg" :class="{noImg:!item.user_id}"><img :src="item.headImg" alt="" v-if="item.headImg"></div>
          </div>
          <div class="processNum">
            <span>{{item.progress ? item.progress : 0}}%</span>
          </div>
          <div>
            <a-popconfirm
                title="确定删除该任务?"
                @confirm="deletTask(item)"
                okText="确定"
                cancelText="取消"
            >
              <icon-font type="icon-shanchu" title="删除任务" style="color:#f5222d"/>
            </a-popconfirm>
          </div>
        </div>
      </div>
    </div>
    <a-pagination v-model="pagination.page" :total="total" v-if="total>0" @change="changePage"/>
    <a-modal
        v-model="visibleUser"
        @ok="hideModal"
        okText="确认"
        cancelText="取消"
        :closable="false"
        :maskClosable="false"
        centered
        destroyOnClose
    >
      <div>
        <a-radio-group name="radioGroup" v-model="sendUserId">
          <a-radio :value="item.id" v-for="(item,index) in userList" :key="index">{{item.nickname}}</a-radio>
        </a-radio-group>
      </div>
    </a-modal>
  </div>
</template>
<script>
import axios from 'axios';
import AddAccount from '@/components/addAccount'
import Loading from '@/components/loading'
import { getTaskList, delTask, editTask, getAllUsers, getCate , BaseUrl } from '@/libs/api'
import { getDocPaper} from '@/libs/analysis'
import { IconFont } from '@/libs/IconFont'
export default {
  name:'task',
  components:{ AddAccount, Loading, IconFont },
  data(){
    return{
      detail_type: 0,
      visibleUser:false,
      current:1,
      addShow:false,
      sendUserId:'',
      loading:true,
      list:[],
      total: 0,
      spinning:true,
      userList:[],
      copyData:'',
      currentId:'',
      pagination:{
        page:1,
        size:10
      },
      cate_id: null,
      options:[],
      cascader:[],
      classB: [
        {
          name: '全部试卷',
          detail_type:0
        },{
          name:'模拟考试',
          detail_type:1
        },{
          name:'专题试卷',
          detail_type:2
        }
      ]
    }
  },
  watch:{
    visibleUser(val){
      if(!val){
        this.sendUserId = ''
      }
    },
    'pagination.page'(val){
      localStorage.setItem('Page', val)
    }
  },
  filters:{
    num(val){
      if(val<9){
        return `0${val+1}`
      }else{
        return val+1
      }
    }
  },
  mounted(){
    let cascader = localStorage.getItem('cascader')
    if(cascader && cascader.length>2){
      this.cascader = cascader.split(',').map(el => Number(el))
      this.cate_id = this.cascader[this.cascader.length-1]
    }
    let page =  localStorage.getItem('Page')
    page? this.pagination.page = Number(page):1
    this.GetCate()
    this.GetAllTasks()
    this.GetAllUsers()
  },
  methods:{
    changeClass({detail_type}){
      this.detail_type = detail_type
      this.GetAllTasks()
    },
    GetCate(){
      return new Promise((resolve,reject)=>{
        getCate().then(res=>{
          if(res.length){
            this.dealArr(res)
            this.options=res
          }
          resolve()
        },error=>{
          console.log(error)
          reject()
        })
      })
    },
    dealArr(data){
      data.forEach(el => {
        if(!el.son.length){
          delete el.son
        }
        if(el.son &&  el.son.length){
          this.dealArr(el.son)
        }
      })
    },
    GetAllTasks(){
      let request= {
        user_id:this.$store.state.userInfo.id,...this.pagination, cate_id: this.cate_id, detail_type: this.detail_type
      }
      getTaskList(request).then(res=>{
        this.loading=false
        if(res){
          let {count,list}=res
          this.list=list
          this.total=count
        }
      },error=>{
        console.log(error)
        this.loading=false
      })
    },
    deletTask({id}){
      delTask({id}).then(res=>{
        console.log(res)
        this.GetAllTasks()
      },error=>{
        console.log(error)
      })
    },
    GetAllUsers(){
      getAllUsers({page:1,size:1000}).then(res=>{
        if(res){
          let {count,list}=res
          this.userList=list
        }
      },error=>{
        console.log(error)
      })
    },
    downLoad(params) {
      getDocPaper({id:params.id}).then(res=>{
        if(res){
          let downUrl = BaseUrl+res;
          window.open(downUrl);
        }
      },error=>{
        console.log(error)
        this.loading=false
      })

    },
    sendFun({user_id , id}){
      this.visibleUser=true
      this.currentId = id
      if(user_id){
        this.sendUserId = user_id
      }
      // let formData = new FormData()
      // formData.append('user_id',user_id)
      // formData.append('id',id)
      // editTask()
    },
    hideModal(){
      if(!this.sendUserId){
        this.$message.error('请选择用户')
        return false
      }
      let formData = new FormData()
      formData.append('user_id', this.sendUserId)
      formData.append('id', this.currentId)
      editTask(formData).then(res=>{
        if(res.length == 0){
          this.visibleUser = false
          this.GetAllTasks()
        }

      },error=>{
        console.log(error)
      })
    },
    onChange(id){
      this.cascader = id
      localStorage.setItem('cascader', id)
      if(id.length){
        this.cate_id = id[id.length-1]
      }else{
        this.cate_id = null
      }
      this.pagination.page = 1
      this.GetAllTasks()
    },
    changePage(val){
      this.pagination.page = val
      this.GetAllTasks()
    },
    rememberPage(){
      let { page } = this.pagination
      localStorage.setItem('Page', page)
    },
    routeTo(path, id){
      this.$router.push({path:`/home/${path}`, query: {id: id}});
    }
  }
}
</script>
<style lang="less" scoped>
.iconGroup{
  >*{
    padding: 0 12px;
    display: inline-block;
    vertical-align: middle;
  }
  .headImg{
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background: #f07e66;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      display: block
    }
    &.noImg{
      background: #fff
    }
  }
  .processNum{
    color: #4165e0;
    width: 75px;
    font-weight: bold
  }
}
.fL .btn{
  background: none;
  color: #5180e0;
  border: 1px solid #5180e0;
  &.active{
    color: #fff;
    background: #5180e0
  }
}
.iconGroup  .anticon{
  font-size:24px;
  color: #4165e0;
  cursor: pointer;
}
.csscsder{
  margin-left: 20px;
  width: 350px
}
</style>
